import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import styled from 'styled-components'


const StyledContainer = styled.div`
h3 {
    font-size: 2.4rem;
    color: #3E92CC;
    font-weight: 700;
}

.comment-author {
    display: inline-block;
    font-size: 1.4rem;
    color: #D81159;
    margin-bottom: -3rem;
    z-index: 1;
    position: relative;
    -webkit-text-stroke-width: .5px;
    -webkit-text-stroke-color: black;
    text-shadow:
      1px 1px 0 #000,
      -1px -1px 0 #000,
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000;

    a {
        color: #D81159;
    }

    &::after {
        content: '';
        display: block;
        height: 60px;
        background-color: #FFB414;
        position: relative;
        left: -7%;
        bottom: 40px;
        z-index: -1;
        opacity: 1;
        transition: all .025s ease-in-out;
        transform: rotate(-5deg);
        width: 120%;
    }
}

.comment-content {
    margin-bottom: 3rem;
    font-size: 1.2rem;
    position: relative;
    z-index: 2;
    text-shadow:
      3px 3px 0 #202020,
      -1px -1px 0 #202020,
      1px -1px 0 #202020,
      -1px 1px 0 #202020,
      1px 1px 0 #202020;
    letter-spacing: 1.5px;
}
`;

// Create a GraphQL query for the comment list.
const commentQuery = gql`
    query($postId: Int) {
        comments(where: { contentId: $postId, contentStatus: PUBLISH }) {
            nodes {
                ...CommentFields
            }
        }
    }

    fragment CommentFields on Comment {
        content
        author {
            ...AuthorFields
        }
    }

    fragment AuthorFields on CommentAuthor {
        name
        url
    }
`;

// Main component class.
class CommentList extends React.Component {
    // Render stuff.
    render() {
        const postId = this.props.postId;
        return (
            // Wrap the comment list in our query.
            <Query query={commentQuery} variables={{ postId }}>
                {({ data }) => {

                    if (data && data.comments.nodes.length < 1 ) {
                        return null;
                    }

                    return (
                        <StyledContainer>
                            <h3>What y’all thought</h3>
                            { data && data.comments.nodes.length > 0 && data.comments.nodes.map((comment, key) => {
                                if (comment.author.url) {
                                    return (
                                        <div key={key} className="comment">
                                            <div className="comment-author">
                                                <a href={comment.author.url}>{comment.author.name}</a> says:
                                            </div>
                                            <div className="comment-content" dangerouslySetInnerHTML={{ __html: comment.content }} />
                                        </div>
                                    )
                                }

                                return (
                                    <div key={key} className="comment">
                                        <div className="comment-author">
                                            {comment.author.name} says:
                                        </div>
                                        <div className="comment-content" dangerouslySetInnerHTML={{ __html: comment.content }} />
                                    </div>
                                )
                            }
                            )}
                        </StyledContainer>
                    );
                }}
            </Query>
        );
    }
}

export default CommentList;