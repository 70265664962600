import React from 'react';
import fetch from 'isomorphic-fetch';
import styled from 'styled-components'


const StyledForm = styled.form`
margin-top: 4rem;
width: 100%;
max-width: 360px;

textarea,
input {
	padding: 10px 15px;
	width: 100%;
}

.Button {
	color: #FFB414;
	background-color: #000000;
	display: inline-block;
	transition: all .2s ease-in-out;
	border: none;
	border-radius: 2px;
	width: auto;
	cursor: pointer;

	&:hover {
		background-color: #FFB414;
		color: #000000;
	}
}
`

const RESTURL = 'http://napi.screamingcodemonkey.com/wp-json/wp/v2';

// Our main component class.
class CommentForm extends React.Component {
	constructor(props) {
		super(props);
		// Set the initial state.
		this.state = {
			commentStatus: false,
			post: props.postId,
			comment: '',
			author: '',
			email: '',
			url: '',
		};

		// Bind input changes.
		this.handleInputChange = this.handleInputChange.bind(this);
		this.submitForm = this.submitForm.bind(this);
	}

	// Handles input change events.
	handleInputChange(event) {
		const target = event.target;
		const value = event.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		// Sets the state of the input field.
		this.setState({
			[name]: value,
		});
	}

	async submitForm(event) {
		// Prevent default form submit behavior.
		event.preventDefault();
		// Set initial loading state on submission.
		this.setState({ commentStatus: 'loading' });

		let params = {
			author_name: this.state.author,
			post: this.state.post,
			content: this.state.comment,
			author_email: this.state.email,
			author_url: this.state.url,
		};

		const searchParams = Object.keys(params).map((key) => {
			return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
		}).join('&');

		await fetch(`${RESTURL}/comments/?${searchParams}`, {
			method: 'POST', // *GET, POST, PUT, DELETE, etc.
			mode: 'no-cors', // no-cors, *cors, same-origin
		});

		// await response.status; // parses JSON response into native JavaScript objects
		this.setState({ commentStatus: 'finished' });
		return null;
	}

	// Renders the comment form elements.
	renderCommentForm() {
		return (
			<StyledForm onSubmit={(event) => {this.submitForm(event)}}>
				<h4>Have something to say?</h4>
				<p>
					<input name="author" required placeholder="Author" value={this.state.author} onChange={this.handleInputChange} />
				</p>
				<p>
					<input name="email" type="email" required placeholder="Email" value={this.state.email} onChange={this.handleInputChange} />
				</p>
				<p>
					<input name="url" required placeholder="Website" value={this.state.url} onChange={this.handleInputChange} />
				</p>
				<p>
					<textarea name="comment" required placeholder="Comment" value={this.state.comment} onChange={this.handleInputChange} />
				</p>
				<p>
					<input name="submit" className="Button" type="submit" value="Post Comment" />
				</p>
			</StyledForm>
		);
	}

	render() {
		const {
			commentStatus
		} = this.state;

		if (commentStatus === 'finished') {
			return <h3>Thanks, your comment is pending.</h3>
		}

		return this.renderCommentForm();
	}
}

export default CommentForm;