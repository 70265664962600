import React, { Component } from 'react'
import Img from "gatsby-image"
import styled from "styled-components"

import { Link } from "gatsby"

const colorArray = [
  {bg: '#DABDAB', color: '#1b1b1b'},
  {bg: '#FFB414', color: '#1b1b1b'},
  {bg: '#D81159', color: '#ddd'},
  {bg: '#3E92CC', color: '#ddd'},
];

const StyledCard = styled.header`
  a {
    text-decoration: none;
    color: #fff;
    position: relative;
    display: block;
    min-width: 250px;
  }

  h3 {

    margin: 0 1rem;
    color: ${props => props.constBGColor.color};
    position: absolute;
    bottom: 1rem;

  }
  .Card-cover {
    position: relative;
    padding-top: 56.25%;
    background-color: ${props => props.constBGColor.bg};
  }

  .Card-bgImg {
    position: absolute !important;
    top: 0;
    width: 100%;
    bottom: 0;
    opacity: .99;

  }
  .Card-highlight {
    background-color: ${props => props.constBGColor.bg};
  }

  .Card-date {
    transform: rotate(-90deg) translate(-125px, 110px);
    right: 0;
    top: 0;
    color: ${props => props.constBGColor.color};
    position: absolute;
    text-align: right;
    min-width: 250px;
    font-size: .75rem;
  }

  .Card-description {
    color: #1b1b1b;
    background-color: #fff;
    padding: 1rem;
  }
`

export default class Card extends Component {
  render() {
    const {
      post,
    } = this.props;

    let fluid = false;

    if (post.featured_media) {
      fluid = post.featured_media.localFile.childImageSharp.fluid;
    }

    const excerpt = (post.excerpt) ? post.excerpt : post.content.substr(0, 150);
    const constBGColor = colorArray[Math.floor(Math.random() * Math.floor(colorArray.length))];

    return (
      <StyledCard constBGColor={constBGColor}>
        <Link to={`/posts/${post.slug}`}>
          <div className="Card-cover">
            {fluid &&
              <Img className="Card-bgImg" fluid={fluid} />
            }
            <h3>
              <span className="Card-highlight">{post.title}</span>
            </h3>
          </div>

          {post.date && post.datetime &&
            <div className="Card-date" dateTime={post.datetime}>{post.date}</div>}

          <div className="Card-description" dangerouslySetInnerHTML={{__html: excerpt}} />
        </Link>
      </StyledCard>
    )
  }
}
