import React from "react"
import styled from "styled-components"

import Card from "../components/card"

const StyledULs = styled.div`
  ul {
    list-style: none;
    padding: .5rem 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    /* @media( min-width: 768px ) {
      margin-left: -1rem;
      margin-right: -1rem;
    } */
  }
  li {
    padding: 1.5rem 1rem;
    margin: 0;
    width: 100%;
    @media( min-width: 768px ) {
      width: 33.333%;
    }
  }
`;

const StyledUL = ({posts}) => (
  <StyledULs>
    <ul>
    {posts.map(({node}) => {
    return (
        <li key={node.slug}>
          <Card post={node} />
        </li>
    )})}
    </ul>
  </StyledULs>
)


export default StyledUL;
