import React from "react"

import StyledUL from "../components/styled-ul"

// import styled from 'styled-components'

const RelatedPosts = ({posts}) => (
  <StyledUL posts={posts} />
)

export default RelatedPosts
