import React, { Component } from 'react'
import {Link, graphql} from "gatsby"
import styled from 'styled-components'

import Layout from "../components/layout"
import CommentForm from "../components/comment-form"
import CommentList from "../components/comment-list"
import Header from "../components/header"
import SEO from "../components/seo"
import RelatedPosts from "../components/related-posts"


const StyledArticle = styled.article`
  max-width: 1800px;
  margin-top: 6rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;

  @media( min-width: 768px ) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .ArticleDate {
    position: absolute;
    transform: rotate(-90deg);
    left: -50px;
    top: 50px;
    @media( min-width: 768px ) {
      left: -50px;
    }
  }

  .MainContent {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4rem;
  }

  .row {
    clear: both;
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .ArticleCategories {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;

    ul {
      margin: 0;
      padding: 0;
      &::after {
        content: '';
        width: 100%;
        height: 2px;
        background-color: #f00;
        display: block;
        position: relative;
        top: -10px;
      }
    }
    li {
      list-style: none;
      text-align: center;
      a {
        background-color: #202020;
        position: relative;
        z-index: 2;
        padding: .5rem;
        text-decoration: none;
        color: #DABDAB;
      }

    }
  }

`;

export default class Post extends Component {
  render() {
    const {
      data: {
        wordpressPost: {
          wordpress_id,
          title,
          excerpt,
          content,
          date,
          datetime,
          categories,
        },
        allWordpressPost: {
          edges,
        }
      }
    } = this.props

    const postId = parseInt(wordpress_id,10);
    // console.log(this.props);

    return (
      <Layout>
        <SEO
          title={title}
          description={excerpt.replace(/<[^>]*>?/gm, '').substr(0, excerpt.length -20)}
        />
        <Header />
        <StyledArticle>
          <h2>{title}</h2>
          <div className="ArticleDate" dateTime={datetime}>{date}</div>
          <div className="ArticleCategories">
            <ul>
              {categories.map((category) => {
                return (
                  <li key={`/category/${category.slug}`}>
                    <Link to={`/category/${category.slug}`}>
                      {category.name}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="MainContent">
            <div dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
            <CommentList postId={postId} />
            <CommentForm postId={postId} />
            <RelatedPosts posts={edges} />
          </div>
        </StyledArticle>
      </Layout>
    )
  }
}

export const query = graphql`
  query PostQuery($slug: String!) {
    wordpressPost(slug: { eq: $slug }) {
      wordpress_id
      title
      date(formatString: "MMMM DD, YYYY")
      datetime: date(formatString: "YYYY-MM-DD hh:mm:ss")
      slug
      content
      excerpt
      categories {
        name
        count
        slug
      }
    }

    allWordpressPost (filter: { slug: { ne: $slug}}, limit:3){
      edges {
        node {
          title
          excerpt
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth:2800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          content
          slug
        }
      }
    }
  }
`
